import "./NotAllowed.scss";
import MainLayout from "../../layouts/MainLayout";



export const NotAllowed = () => {
  

  return (
    <MainLayout className="register-page">
      <div className="participated-form">
        <p className="scan-text">Please scan the QR Code to Participate</p>
      </div>
      
    </MainLayout>
  );
};
export default NotAllowed;
